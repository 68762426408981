import Tools from '@/utils/Tools';
import { RequestOptionsInit, extend } from 'umi-request';
import api_config from './config';
// import { message } from 'antd';

export const API_URL = api_config.API_URL;

const request = extend({
  timeout: 30000,
});

request.interceptors.request.use((url, options) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + Tools.getData('access_token'),
  };
  if (Object.keys(options.headers ?? {}).length > 0) {
    let new_headers: any = {
      Authorization: 'Bearer ' + Tools.getData('access_token'),
      ...options.headers,
    };
    // remove keys with undefined values
    new_headers = Object.keys(new_headers).reduce((acc: any, key) => {
      if (new_headers[key] !== undefined) {
        acc[key] = new_headers[key];
      }
      return acc;
    }, {});
    return {
      url: url,
      options: { ...options, headers: new_headers },
    };
  }
  return {
    url: url,
    options: { ...options, headers: headers },
  };
});

const retry = (response: Response, options: RequestOptionsInit) => {
  return request(response.url, options);
};

const refreshToken = async () => {
  // const response = await request.put(API_URL + '/api/tokens', {
  //   data: {
  //     refresh_token: Tools.getData('refresh_token'),
  //     access_token: Tools.getData('access_token'),
  //   },
  // });
  const response = await fetch(API_URL + '/api/tokens', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh_token: Tools.getData('refresh_token'),
      access_token: Tools.getData('access_token'),
    }),
  });

  return response.json();
};

export const quickLogout = () => {
  Tools.removeData('access_token');
  Tools.removeData('refresh_token');
  Tools.removeData('current_user');
  Tools.removeData('access_token_expiration');
  Tools.removeData('refresh_token_expiration');

  window.location.href = '/user/login';
};

let isRefreshing: boolean = false;
let failedQueue: any = [];
const processQueue = (error: any) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

request.interceptors.response.use(async (response, options) => {
  const ret = response.clone();

  let ret_json = null;
  try {
    ret_json = await ret.json();
  } catch (e) {
    // Clone the response again before trying to read as text
    const retCloneForText = response.clone();
    try {
      return await retCloneForText.text();
    } catch (textError) {
      return response;
    }
  }
  const { result, success } = ret_json;
  if (result === undefined || success === undefined) {
    return ret_json;
  }
  if (success) {
    return result;
  }
  const { code, message } = result;
  if (code === 401) {
    if (isRefreshing) {
      return new Promise<Response>((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(() => {
          console.log('retry------------------------');
          return retry(response, options);
        })
        .catch((err) => {
          console.log('Promise.reject------------------------', err);
          return Promise.reject(err);
        });
    }
    isRefreshing = true;

    return new Promise<Response>((resolve, reject) => {
      refreshToken()
        .then((res_) => {
          const tokenData = res_?.result || res_;
          console.log('tokenData------------------------', tokenData);
          if (!tokenData || !tokenData.access_token || !tokenData.refresh_token) {
            quickLogout();
          }
          Tools.setData('access_token', tokenData.access_token);
          Tools.setData('refresh_token', tokenData.refresh_token);
          Tools.setData('current_user', JSON.stringify(tokenData.profile));
          Tools.setData('access_token_expiration', tokenData.access_token_expiration ?? '');
          Tools.setData('refresh_token_expiration', tokenData.refresh_token_expiration ?? '');

          processQueue(null);
          resolve(retry(response, options));
        })
        .catch((err) => {
          console.log('err------------------------', err);
          failedQueue = [];
          processQueue(err);
          reject(err);
          quickLogout();
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  } else if (code === 400 || code === 403) {
    console.log('result------------------------', result);
    return [];
  } else {
    throw new Error(message);
  }
});

export async function get(api_route: string, options?: object): Promise<any> {
  return request.get(API_URL + api_route, {
    params: options,
  });
}

export async function put(api_route: string, data?: object): Promise<any> {
  return request.put(API_URL + api_route, {
    data: data,
  });
}

export async function post(api_route: string, data?: object, headers?: object): Promise<any> {
  return request.post(API_URL + api_route, {
    data: data,
    headers: headers as HeadersInit,
  });
}

export async function delete_(api_route: string, data?: object): Promise<any> {
  return request.delete(API_URL + api_route, {
    data: data,
  });
}
