import { outLogin } from '@/services/ant-design-pro/api';
import { get } from '@/services/requestWrapper';
import Tools from '@/utils/Tools';
import colors from '@/utils/styles/colors';
import { LogoutOutlined, SoundOutlined, UserOutlined } from '@ant-design/icons';
import { history, useIntl, useModel } from '@umijs/max';
import { Button, Modal, Spin, message } from 'antd';
// const { Paragraph } = Typography;

import { createStyles } from 'antd-style';
import { stringify } from 'querystring';
import type { MenuInfo } from 'rc-menu/lib/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import HeaderDropdown from '../HeaderDropdown';
import Loading from '../Loading';
import NewlineText from '../NewLineText';

export type GlobalHeaderRightProps = {
  menu?: boolean;
  children?: React.ReactNode;
};

export const AvatarName = () => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};
  if (window.innerWidth < 576) {
    return null;
  }
  return (
    <span
      className="anticon"
      style={{
        color: 'white',
        fontSize: '16px',
      }}
    >
      {currentUser?.name}
    </span>
  );
};

const useStyles = createStyles(({ token }) => {
  return {
    action: {
      display: 'flex',
      height: '48px',
      marginLeft: 'auto',
      overflow: 'hidden',
      alignItems: 'center',
      padding: '0 8px',
      cursor: 'pointer',
      borderRadius: token.borderRadius,
      '&:hover': {
        backgroundColor: token.colorBgTextHover,
      },
    },
  };
});

// const Info: React.FC<{
//   title: React.ReactNode;
//   value: React.ReactNode;
//   bordered?: boolean;
// }> = ({ title, value, bordered }) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <span>{title}</span>
//       <p>{value}</p>
//       {bordered && <em />}
//     </div>
//   );
// };

const StarMaker = () => {

  const current_page_ = window.location.pathname.split('/')[1];
  
  const advice_permission = Tools.verifyPermission(`/api/ai/${current_page_}_advice`, 'GET');

  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const ModalContent = () => {
    const current_page = Tools.getData('current_page');
    // const count = parseInt(Tools.getData('count') || '0');
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [AIText, setAIText] = useState<string | null>(null);
    useEffect(() => {
      return () => {
        if (audio) {
          (audio as HTMLAudioElement).pause();
          setAudio(null);
        }
      };
    }, [audio]);

    useEffect(() => {
      if (!current_page) return;
      if (AIText) return;
      switch (current_page) {
        case 'goals': {
          if (!advice_permission) {
            setAIText('You do not have permission to access this feature.');
            return;
          }
          get('/api/ai/goals_advice')
            .then((res: any) => {
              if (res) {
                setAIText(res.text);
                setAudio(new Audio(res.audio));
                return;
              }
              setAIText('No data');
            })
            .catch((err) => {
              console.log('err', err);
              setAIText('No data');
            });
          break;
        }
        case 'sessions': {
          if (!advice_permission) {
            setAIText('You do not have permission to access this feature.');
            return;
          }
          get('/api/ai/sessions_advice')
            .then((res: any) => {
              if (res) {
                setAIText(res.text);
                setAudio(new Audio(res.audio));
                return;
              }
              setAIText('No data');
            })
            .catch((err) => {
              console.log('err', err);
              setAIText('No data');
            });
          break;
        }
        case 'action_items': {
          if (!advice_permission) {
            setAIText('You do not have permission to access this feature.');
            return;
          }
          get('/api/ai/action_items_advice')
            .then((res: any) => {
              if (res) {
                setAIText(res.text);
                setAudio(new Audio(res.audio));
                return;
              }
              setAIText('No data');
            })
            .catch((err) => {
              console.log('err', err);
              setAIText('No data');
            });
          break;
        }
        default: {
          if (!advice_permission) {
            setAIText('You do not have permission to access this feature.');
            return;
          }
          get('/api/ai/home_advice')
            .then((res: any) => {
              if (res) {
                setAIText(res.text);
                setAudio(new Audio(res.audio));
                return;
              }
              setAIText('No data');
            })
            .catch((err) => {
              console.log('err', err);
              setAIText('No data');
            });
          break;
        }
      }
    }, []);

    const playAudio = () => {
      if (audio) {
        audio.play();
      } else {
        message.info('Audio is loading. Please wait a few seconds and try again.');
      }
    };

    switch (current_page) {
      case 'goals': {
        if (!advice_permission) {
          return <></>;
        }
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
                gap: 10,
              }}
            >
              <span
                style={{
                  fontSize: 20,
                }}
              >
                {intl.formatMessage({
                  id: 'pages.goals.digest',
                })}
              </span>
              <SoundOutlined
                style={{
                  fontSize: 20,
                  color: colors.colorPalet5,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  playAudio();
                }}
              />
            </div>
            <div>
              <Loading isLoading={AIText === null} className="">
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  <NewlineText text={AIText ?? ''} />
                </span>
              </Loading>
            </div>
          </div>
        );
      }
      case 'sessions':
        if (!advice_permission) {
          return <></>;
        }
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
                gap: 10,
              }}
            >
              <span
                style={{
                  fontSize: 20,
                }}
              >
                {intl.formatMessage({
                  id: 'pages.sessions.digest',
                })}
              </span>
              <SoundOutlined
                style={{
                  fontSize: 20,
                  color: colors.colorPalet5,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  playAudio();
                }}
              />
            </div>
            <div>
              <Loading isLoading={AIText === null} className="">
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  <NewlineText text={AIText ?? ''} />
                </span>
              </Loading>
            </div>
          </div>
        );
      case 'action_items':
        if (!advice_permission) {
          return <></>;
        }
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
                gap: 10,
              }}
            >
              <span
                style={{
                  fontSize: 20,
                }}
              >
                {intl.formatMessage({
                  id: 'pages.action_items.digest',
                })}
              </span>
              <SoundOutlined
                style={{
                  fontSize: 20,
                  color: colors.colorPalet5,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  playAudio();
                }}
              />
            </div>
            <div>
              <Loading isLoading={AIText === null} className="">
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  <NewlineText text={AIText ?? ''} />
                </span>
              </Loading>
            </div>
          </div>
        );
      default:
        if (!advice_permission) {
          return <></>;
        }
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
                gap: 10,
              }}
            >
              <span
                style={{
                  fontSize: 20,
                }}
              >
                {intl.formatMessage({
                  id: 'pages.welcome.daily_digest',
                })}
              </span>
              <SoundOutlined
                style={{
                  fontSize: 20,
                  color: colors.colorPalet5,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  playAudio();
                }}
              />
            </div>
            <div>
              <Loading isLoading={AIText === null} className="">
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  <NewlineText text={AIText ?? ''} />
                </span>
              </Loading>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        width={700}
        destroyOnClose={true}
      >
        <ModalContent />
      </Modal>
      {advice_permission && (
        <Button
          id={'hover_effect'}
          style={{
            backgroundColor: colors.colorPalet5,
            fontWeight: '600',
            color: colors.paletContrast,
          }}
          onClick={() => {
            // get('/api/users/daily_digest').then(() => {
            //   // setDailyDigest(res);
            //   setIsModalOpen(true);
            // });
            setIsModalOpen(true);
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 5,
            }}
          >
            <img
              alt="logo"
              src="/logo2.svg"
              style={{
                width: 20,
                height: 20,
              }}
            />
            {intl.formatMessage({
              id: 'pages.welcome.talk_star_maker',
            })}
          </div>
        </Button>
      )}
    </>
  );
};

// export const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu, children }) => {
export const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ children }) => {
  const intl = useIntl();

  const [audio] = useState(null);
  // const [audio, setAudio] = useState(null);
  useEffect(() => {
    if (audio) {
      (audio as HTMLAudioElement).play();
    }
  }, [audio]);

  const loginOut = async () => {
    await outLogin();
    Tools.removeData('access_token');
    Tools.removeData('refresh_token');
    Tools.removeData('current_user');
    Tools.removeData('access_token_expiration');
    Tools.removeData('refresh_token_expiration');

    const { search, pathname } = window.location;
    const urlParams = new URL(window.location.href).searchParams;
    const redirect = urlParams.get('redirect');
    if (window.location.pathname !== '/user/login' && !redirect) {
      history.replace({
        pathname: '/user/login',
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }
  };
  const { styles } = useStyles();

  const { initialState, setInitialState } = useModel('@@initialState');

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout') {
        flushSync(() => {
          setInitialState((s) => ({ ...s, currentUser: undefined }));
        });
        loginOut();
        return;
      }
      history.push(`/account/${key}`);
    },
    [setInitialState],
  );

  const loading = (
    <span className={styles.action}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.name) {
    return loading;
  }

  const menuItems = [
    // ...(menu
    //   ? [
    //       {
    //         key: 'center',
    //         icon: <UserOutlined />,
    //         label: intl.formatMessage({
    //           id: 'pages.home.center',
    //         }),
    //       },
    //       {
    //         key: 'settings',
    //         icon: <SettingOutlined />,
    //         label: intl.formatMessage({
    //           id: 'pages.home.settings',
    //         }),
    //       },
    //       {
    //         type: 'divider' as const,
    //       },
    //     ]
    //   : []),
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: intl.formatMessage({
        id: 'pages.profile',
      }),
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: intl.formatMessage({
        id: 'pages.home.logout',
      }),
    },
  ];
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
      }}
    >
      <StarMaker />
      <HeaderDropdown
        menu={{
          selectedKeys: [],
          onClick: onMenuClick,
          items: menuItems,
        }}
      >
        {children}
      </HeaderDropdown>
      <span
        style={{
          color: 'white',
          fontSize: '16px',
          rotate: '45deg',
          marginRight: 10,
          marginLeft: -10,
        }}
      >
        BETA
      </span>
    </div>
  );
};
