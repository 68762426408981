import component from './pt-PT/component';
import globalHeader from './pt-PT/globalHeader';
import menu from './pt-PT/menu';
import pages from './pt-PT/pages';
import pwa from './pt-PT/pwa';
import settingDrawer from './pt-PT/settingDrawer';
import settings from './pt-PT/settings';

export default {
  'navBar.lang': 'Idiomas',
  'layout.user.link.help': 'ajuda',
  'layout.user.link.privacy': 'política de privacidade',
  'layout.user.link.terms': 'termos de serviços',
  'app.preview.down.block': 'Download this page to your local project',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
};
