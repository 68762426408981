// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/user","layout":false,"id":"1"},"2":{"name":"login","path":"/user/login","parentId":"1","id":"2"},"3":{"name":"recover_password","path":"/user/recover_password","parentId":"1","id":"3"},"4":{"path":"","name":"home","icon":"home","parentId":"5","id":"4","originPath":"/welcome"},"5":{"path":"/welcome","isWrapper":true,"parentId":"ant-design-pro-layout","id":"5"},"6":{"path":"/","redirect":"/welcome","parentId":"ant-design-pro-layout","id":"6"},"7":{"path":"*","layout":false,"id":"7"},"8":{"path":"/greetings","layout":false,"id":"8"},"9":{"path":"/goals","name":"goals","icon":"rocket","data_tour":"goals_step_1","goToNextStep":true,"parentId":"ant-design-pro-layout","id":"9"},"10":{"path":"/goals/:id","parentId":"ant-design-pro-layout","id":"10"},"11":{"path":"/sessions","name":"sessions","icon":"calendar","data_tour":"sessions_step","parentId":"ant-design-pro-layout","id":"11"},"12":{"path":"/sessions/:id","parentId":"ant-design-pro-layout","id":"12"},"13":{"path":"/action_items","name":"action_items","icon":"CheckCircleOutlined","data_tour":"action_items_step","parentId":"ant-design-pro-layout","id":"13"},"14":{"path":"/action_items/:id","parentId":"ant-design-pro-layout","id":"14"},"15":{"path":"/clean_up","parentId":"ant-design-pro-layout","id":"15"},"16":{"path":"/weekly_planner","name":"weekly_planner","icon":"FormOutlined","parentId":"ant-design-pro-layout","id":"16"},"17":{"path":"/coach","name":"coach","icon":"TeamOutlined","parentId":"ant-design-pro-layout","id":"17"},"18":{"path":"/help_desk","name":"help_desk","icon":"QuestionCircleOutlined","parentId":"ant-design-pro-layout","id":"18"},"19":{"path":"/account/profile","parentId":"ant-design-pro-layout","id":"19"},"20":{"path":"/maintenance","parentId":"ant-design-pro-layout","id":"20"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__User__Login__index" */'@/pages/User/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__User__RecoverPassword__index" */'@/pages/User/RecoverPassword/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__Welcome" */'@/pages/Welcome.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "wrappers__auth" */'@/wrappers/auth.tsx')),
'6': React.lazy(() => import('./EmptyRoute')),
'7': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__Greetings" */'@/pages/Greetings.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__Goals" */'@/pages/Goals.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__Goal" */'@/pages/Goal.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__Sessions" */'@/pages/Sessions.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__Session" */'@/pages/Session.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__ActionItems" */'@/pages/ActionItems.tsx')),
'14': React.lazy(() => import(/* webpackChunkName: "p__ActionItem" */'@/pages/ActionItem.tsx')),
'15': React.lazy(() => import(/* webpackChunkName: "p__CleanUp" */'@/pages/CleanUp.tsx')),
'16': React.lazy(() => import(/* webpackChunkName: "p__WeeklyPlanner" */'@/pages/WeeklyPlanner.tsx')),
'17': React.lazy(() => import(/* webpackChunkName: "p__Coach" */'@/pages/Coach.tsx')),
'18': React.lazy(() => import(/* webpackChunkName: "p__HelpDesk" */'@/pages/HelpDesk.tsx')),
'19': React.lazy(() => import(/* webpackChunkName: "p__Account" */'@/pages/Account.tsx')),
'20': React.lazy(() => import(/* webpackChunkName: "p__Maintenance" */'@/pages/Maintenance.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/home/andre/Desktop/pramod/fredomaker/web/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
