import { AvatarDropdown, AvatarName, SelectLang, TalkStarMaker } from '@/components';
import { currentUser as queryCurrentUser } from '@/services/ant-design-pro/api';
import { history } from '@@/core/history';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import { SettingDrawer } from '@ant-design/pro-components';
import { TourProvider, useTour } from '@reactour/tour';
import * as Sentry from '@sentry/react';
import { Link, type RunTimeLayoutConfig } from '@umijs/max';
import { Button, Flex, Modal, Typography } from 'antd';
import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useState,
} from 'react';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import { put } from './services/requestWrapper';
import Tools from './utils/Tools';
import colors from './utils/styles/colors';
const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/user/login';

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
const fetchUserInfo = async () => {
  let currentUser = undefined;
  try {
    currentUser = await queryCurrentUser();
  } catch (error) {
    history.push(loginPath);
  }
  return currentUser;
};

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const { location } = history;
  if (location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }
  return {
    fetchUserInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({
  initialState,
  setInitialState,
}: {
  initialState: any;
  setInitialState: any;
}) => {
  return {
    actionsRender: () => [<SelectLang key="SelectLang" />, <TalkStarMaker key="TalkStarMaker" />], //<Question key="doc" />,
    avatarProps: {
      src: initialState?.currentUser?.avatar_url || '/icons/avatar.svg',
      title: <AvatarName />,
      render: (
        _: any,
        avatarChildren:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | Iterable<ReactNode>
          | ReactPortal
          | null
          | undefined,
      ) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    pageTitleRender: false,
    breadcrumbRender: false,
    menuItemRender: (item, defaultDom) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (item.path === '/help_desk') {
        return (
          <div>
            <div style={{ height: 2, backgroundColor: '#000' }} />
            <Link to={item.path + ''}>{defaultDom}</Link>
          </div>
        );
      }
      const nav_permission = Tools.verifyNavigationPermission(item.path);
      if (!nav_permission) {
        return <></>;
      }
      return (
        <Link
          to={item.path + ''}
          data-tour={item.data_tour}
          state={{ goToNextStep: item.goToNextStep }}
        >
          {defaultDom}
        </Link>
      );
    },
    // footerRender: () => <Footer />,
    onPageChange: async () => {
      const { location } = history;
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        if (!initialState?.currentUser) {
          const currentUser = await fetchUserInfo();
          if (currentUser) {
            setInitialState((preInitialState: any) => ({
              ...preInitialState,
              currentUser,
            }));
          } else {
            history.push(loginPath);
          }
        }
      }
    },
    childrenRender: (children: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isModalOpen, setIsModalOpen] = useState(false);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { setIsOpen, setCurrentStep } = useTour();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      // useEffect(() => {
      //   if (initialState?.currentUser?.tour_completed === false) {
      //     setIsModalOpen(true);
      //   }
      // }, []);

      const ModalContent = () => {
        return (
          <>
            <Typography.Title level={3}>Welcome {initialState?.currentUser?.name}</Typography.Title>
            <Typography.Text>
              Thanks for joining us. We are excited to have you here. We have a few steps to get you
              started.
            </Typography.Text>
            <Flex justify="space-between">
              <Button
                id={'hover_effect'}
                style={{
                  backgroundColor: colors.colorPalet5,
                  fontWeight: '600',
                  color: colors.paletContrast,
                }}
                onClick={() => {
                  put(`/api/users/${initialState?.currentUser?.id}`, {
                    tour_completed: true,
                  }).then((user) => {
                    Tools.setData('current_user', JSON.stringify(user));
                    setIsModalOpen(false);
                  });
                }}
              >
                Skip
              </Button>
              <Button
                id={'hover_effect'}
                style={{
                  backgroundColor: colors.colorPalet5,
                  fontWeight: '600',
                  color: colors.paletContrast,
                }}
                onClick={() => {
                  setCurrentStep(0);
                  setIsModalOpen(false);
                  setIsOpen(true);
                }}
              >
                Start Tour
              </Button>
            </Flex>
          </>
        );
      };

      return (
        <div>
          <Modal
            open={isModalOpen}
            footer={null}
            onCancel={() => setIsModalOpen(false)}
            destroyOnClose={true}
          >
            <ModalContent />
          </Modal>
          {children}
          {isDev && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState: any) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )}
        </div>
      );
    },
    ...initialState?.settings,
  };
};

const FinishTourComponent = () => {
  const { setIsOpen } = useTour();

  return (
    <Button
      id={'hover_effect'}
      style={{
        backgroundColor: colors.colorPalet5,
        fontWeight: '600',
        color: colors.paletContrast,
      }}
      onClick={() => {
        console.log('Finish------------------');
        setIsOpen(false);
      }}
    >
      Finish
    </Button>
  );
};

const MyProvider = ({ children, routes }: any) => {
  const newChildren = React.cloneElement(children, {
    ...children.props,
    routes,
  });

  const steps = [
    {
      selector: '[data-tour="goals_step_1"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Goals</Typography.Title>
          <Typography.Text>Here you can see your goals. Click to navigate there.</Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="goals_step_2"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Goals</Typography.Title>
          <Typography.Text>Create a new goal</Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="goals_step_3"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Goals</Typography.Title>
          <Typography.Text>
            Type in the goal name. For example: Develep a new Minimum viable product. (To submit you
            can press enter or click the check button)
          </Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="goals_step_4"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Goals</Typography.Title>
          <Typography.Text>Click on the goal to see more details.</Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="goals_step_5"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Goals</Typography.Title>
          <Typography.Text>Click to get some action items</Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="goals_step_6"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Goals</Typography.Title>
          <Typography.Text>
            Now you can accept/reject the suggested action items. After that you can click one to
            see more details
          </Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="action_items_step_1"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Action Items</Typography.Title>
          <Typography.Text>
            In this page you can see all the details about this action item. Click the calendar icon
            to schedule a session
          </Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="action_items_step_2"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Action Items</Typography.Title>
          <Typography.Text>
            Check if the title of the session is alright and you can chose a date for the session,
            then click ok to schedule the session.
          </Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="sessions_step_1"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Sessions</Typography.Title>
          <Typography.Text>
            In this page you can see all the details about this session. Click the button to get
            some recomendations of agenda for the session.
          </Typography.Text>
        </Flex>
      ),
    },
    {
      selector: '[data-tour="sessions_step_2"]',
      content: (
        <Flex vertical={true}>
          <Typography.Title level={3}>Sessions</Typography.Title>
          <Typography.Text>
            You can accept/reject the suggested agenda items. And thats it for the main tour! You
            are ready to start. You can delete the items created in this tour.
          </Typography.Text>
          <FinishTourComponent />
        </Flex>
      ),
    },
  ];

  return (
    <TourProvider
      steps={steps}
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': colors.colorPalet5,
          borderRadius: 30,
        }),
        maskArea: (base) => ({ ...base, rx: 10 }),
        maskWrapper: (base) => ({ ...base, color: '#aaaaaa' }),
        badge: (base) => ({ ...base }),
        controls: (base) => ({ ...base }),
        close: (base) => ({ ...base }),
      }}
      beforeClose={() => {
        put(`/api/users/logged_user`, {
          tour_completed: true,
        }).then((user) => {
          console.log(user);
          Tools.setData('current_user', JSON.stringify(user));
          // setInitialState((preInitialState: any) => ({
          //   ...preInitialState,
          //   currentUser: {
          //     ...preInitialState.currentUser,
          //     tour_completed: true,
          //   },
          // }));
        });
      }}
      showNavigation={false}
    >
      {newChildren}
    </TourProvider>
  );
};

export function rootContainer(container: any) {
  if (!isDev) {
    Sentry.init({
      dsn: 'https://fa8adb7d3d3d14438666d1826f56ee60@o4507310538620928.ingest.de.sentry.io/4507310543339600',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/makeshopprod\.azurewebsites\.net\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  return React.createElement(MyProvider, null, container);
}

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};
