import colors from './colors';

const goalProgress = {
  fontSize: 16,
};
export default {
  actionIcons: {
    fontSize: 28,
    color: colors.colorPalet2,
    cursor: 'pointer',
  },
  addItem: {
    backgroundColor: colors.colorPalet2,
    color: colors.paletContrast,
  },

  aiButton: {
    backgroundColor: colors.colorPalet5,
    color: colors.paletContrast,
    display: 'flex',
    gap: 5,
    fontWeight: '600',
    alignItems: 'center',
  },

  goalProgressToDo: {
    ...goalProgress,
    color: 'red',
  },
  goalProgressDoing: {
    ...goalProgress,
    color: colors.colorPalet5,
  },
  goalProgressDone: {
    ...goalProgress,
    color: 'green',
  },

  tableRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f0f0f0',
    padding: 8,
    cursor: 'pointer',
  },
  cardHeader: {
    backgroundColor: colors.primary,
    color: colors.paletContrast,
  },
  link: {
    color: colors.paletContrast,
    fontWeight: 'bold',
  },
  tableTitle: {
    fontSize: 16,
    fontWeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '70%',
  },
  tableTitle2: {
    fontSize: 16,
    fontWeight: 'bold',
    width: '100%',
    color: colors.primary,
  },
  tableTitleDescription: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: 8,
    width: '70%',
  },
  tableContent: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  tableContentOpacity: {
    opacity: 0.45,
    fontSize: 16,
  },
  tableDescription: {
    opacity: 0.45,
    fontSize: 14,
  },
  loginButton: {
    width: '100%',
    backgroundColor: colors.colorPalet5,
  },
  loginlink: {
    color: colors.colorPalet2,
  },
  input: {
    borderWidth: '0px 0px 1px 0px',
    borderRadius: '0px',
    boxShadow: 'none',
  },
};
