import Empty from '@/components/Empty';
import { get } from '@/services/requestWrapper';
import colors from '@/utils/styles/colors';
import global from '@/utils/styles/global';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { SelectLang as UmiSelectLang, getLocale, history, useIntl } from '@umijs/max';
import { Button, Card, Col, List, Modal, Row, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';

export type SiderTheme = 'light' | 'dark';

const defaultLangUConfigMap: {
  [key: string]: { lang: string; label: string; icon: string; title: string };
} = {
  'ar-EG': {
    lang: 'ar-EG',
    label: 'العربية',
    icon: '🇪🇬',
    title: 'لغة',
  },
  'az-AZ': {
    lang: 'az-AZ',
    label: 'Azərbaycan dili',
    icon: '🇦🇿',
    title: 'Dil',
  },
  'bg-BG': {
    lang: 'bg-BG',
    label: 'Български език',
    icon: '🇧🇬',
    title: 'език',
  },
  'bn-BD': {
    lang: 'bn-BD',
    label: 'বাংলা',
    icon: '🇧🇩',
    title: 'ভাষা',
  },
  'ca-ES': {
    lang: 'ca-ES',
    label: 'Catalá',
    icon: '🇨🇦',
    title: 'llengua',
  },
  'cs-CZ': {
    lang: 'cs-CZ',
    label: 'Čeština',
    icon: '🇨🇿',
    title: 'Jazyk',
  },
  'da-DK': {
    lang: 'da-DK',
    label: 'Dansk',
    icon: '🇩🇰',
    title: 'Sprog',
  },
  'de-DE': {
    lang: 'de-DE',
    label: 'Deutsch',
    icon: '🇩🇪',
    title: 'Sprache',
  },
  'el-GR': {
    lang: 'el-GR',
    label: 'Ελληνικά',
    icon: '🇬🇷',
    title: 'Γλώσσα',
  },
  'en-GB': {
    lang: 'en-GB',
    label: 'English',
    icon: '🇬🇧',
    title: 'Language',
  },
  'en-US': {
    lang: 'en-US',
    label: 'English',
    icon: '🇺🇸',
    title: 'Language',
  },
  'es-ES': {
    lang: 'es-ES',
    label: 'Español',
    icon: '🇪🇸',
    title: 'Idioma',
  },
  'et-EE': {
    lang: 'et-EE',
    label: 'Eesti',
    icon: '🇪🇪',
    title: 'Keel',
  },
  'fa-IR': {
    lang: 'fa-IR',
    label: 'فارسی',
    icon: '🇮🇷',
    title: 'زبان',
  },
  'fi-FI': {
    lang: 'fi-FI',
    label: 'Suomi',
    icon: '🇫🇮',
    title: 'Kieli',
  },
  'fr-BE': {
    lang: 'fr-BE',
    label: 'Français',
    icon: '🇧🇪',
    title: 'Langue',
  },
  'fr-FR': {
    lang: 'fr-FR',
    label: 'Français',
    icon: '🇫🇷',
    title: 'Langue',
  },
  'ga-IE': {
    lang: 'ga-IE',
    label: 'Gaeilge',
    icon: '🇮🇪',
    title: 'Teanga',
  },
  'he-IL': {
    lang: 'he-IL',
    label: 'עברית',
    icon: '🇮🇱',
    title: 'שפה',
  },
  'hi-IN': {
    lang: 'hi-IN',
    label: 'हिन्दी, हिंदी',
    icon: '🇮🇳',
    title: 'भाषा: हिन्दी',
  },
  'hr-HR': {
    lang: 'hr-HR',
    label: 'Hrvatski jezik',
    icon: '🇭🇷',
    title: 'Jezik',
  },
  'hu-HU': {
    lang: 'hu-HU',
    label: 'Magyar',
    icon: '🇭🇺',
    title: 'Nyelv',
  },
  'hy-AM': {
    lang: 'hu-HU',
    label: 'Հայերեն',
    icon: '🇦🇲',
    title: 'Լեզու',
  },
  'id-ID': {
    lang: 'id-ID',
    label: 'Bahasa Indonesia',
    icon: '🇮🇩',
    title: 'Bahasa',
  },
  'it-IT': {
    lang: 'it-IT',
    label: 'Italiano',
    icon: '🇮🇹',
    title: 'Linguaggio',
  },
  'is-IS': {
    lang: 'is-IS',
    label: 'Íslenska',
    icon: '🇮🇸',
    title: 'Tungumál',
  },
  'ja-JP': {
    lang: 'ja-JP',
    label: '日本語',
    icon: '🇯🇵',
    title: '言語',
  },
  'ku-IQ': {
    lang: 'ku-IQ',
    label: 'کوردی',
    icon: '🇮🇶',
    title: 'Ziman',
  },
  'kn-IN': {
    lang: 'kn-IN',
    label: 'ಕನ್ನಡ',
    icon: '🇮🇳',
    title: 'ಭಾಷೆ',
  },
  'ko-KR': {
    lang: 'ko-KR',
    label: '한국어',
    icon: '🇰🇷',
    title: '언어',
  },
  'lv-LV': {
    lang: 'lv-LV',
    label: 'Latviešu valoda',
    icon: '🇱🇮',
    title: 'Kalba',
  },
  'mk-MK': {
    lang: 'mk-MK',
    label: 'македонски јазик',
    icon: '🇲🇰',
    title: 'Јазик',
  },
  'mn-MN': {
    lang: 'mn-MN',
    label: 'Монгол хэл',
    icon: '🇲🇳',
    title: 'Хэл',
  },
  'ms-MY': {
    lang: 'ms-MY',
    label: 'بهاس ملايو‎',
    icon: '🇲🇾',
    title: 'Bahasa',
  },
  'nb-NO': {
    lang: 'nb-NO',
    label: 'Norsk',
    icon: '🇳🇴',
    title: 'Språk',
  },
  'ne-NP': {
    lang: 'ne-NP',
    label: 'नेपाली',
    icon: '🇳🇵',
    title: 'भाषा',
  },
  'nl-BE': {
    lang: 'nl-BE',
    label: 'Vlaams',
    icon: '🇧🇪',
    title: 'Taal',
  },
  'nl-NL': {
    lang: 'nl-NL',
    label: 'Nederlands',
    icon: '🇳🇱',
    title: 'Taal',
  },
  'pl-PL': {
    lang: 'pl-PL',
    label: 'Polski',
    icon: '🇵🇱',
    title: 'Język',
  },
  'pt-BR': {
    lang: 'pt-BR',
    label: 'Português',
    icon: '🇧🇷',
    title: 'Idiomas',
  },
  'pt-PT': {
    lang: 'pt-PT',
    label: 'Português',
    icon: '🇵🇹',
    title: 'Idiomas',
  },
  'ro-RO': {
    lang: 'ro-RO',
    label: 'Română',
    icon: '🇷🇴',
    title: 'Limba',
  },
  'ru-RU': {
    lang: 'ru-RU',
    label: 'Русский',
    icon: '🇷🇺',
    title: 'язык',
  },
  'sk-SK': {
    lang: 'sk-SK',
    label: 'Slovenčina',
    icon: '🇸🇰',
    title: 'Jazyk',
  },
  'sr-RS': {
    lang: 'sr-RS',
    label: 'српски језик',
    icon: '🇸🇷',
    title: 'Језик',
  },
  'sl-SI': {
    lang: 'sl-SI',
    label: 'Slovenščina',
    icon: '🇸🇱',
    title: 'Jezik',
  },
  'sv-SE': {
    lang: 'sv-SE',
    label: 'Svenska',
    icon: '🇸🇪',
    title: 'Språk',
  },
  'ta-IN': {
    lang: 'ta-IN',
    label: 'தமிழ்',
    icon: '🇮🇳',
    title: 'மொழி',
  },
  'th-TH': {
    lang: 'th-TH',
    label: 'ไทย',
    icon: '🇹🇭',
    title: 'ภาษา',
  },
  'tr-TR': {
    lang: 'tr-TR',
    label: 'Türkçe',
    icon: '🇹🇷',
    title: 'Dil',
  },
  'uk-UA': {
    lang: 'uk-UA',
    label: 'Українська',
    icon: '🇺🇰',
    title: 'Мова',
  },
  'vi-VN': {
    lang: 'vi-VN',
    label: 'Tiếng Việt',
    icon: '🇻🇳',
    title: 'Ngôn ngữ',
  },
  'zh-CN': {
    lang: 'zh-CN',
    label: '简体中文',
    icon: '🇨🇳',
    title: '语言',
  },
  'zh-TW': {
    lang: 'zh-TW',
    label: '繁體中文',
    icon: '🇭🇰',
    title: '語言',
  },
};
export const SelectLang = ({ icon_style }: { icon_style?: React.CSSProperties }) => {
  return <></>;
  return (
    <UmiSelectLang
      style={{
        padding: 4,
      }}
      icon={
        <div
          style={{
            display: 'flex',
            gap: 5,
          }}
        >
          <span style={{
            color: "white",
            ...icon_style
          }}>
          {defaultLangUConfigMap[getLocale()]?.icon}
          </span>
          <DownOutlined
            style={{
              fontSize: 10,
              color: "white",
              ...icon_style
            }}
          />
        </div>
      }
    />
  );
};

export const TalkStarMaker = () => {
  return <></>;
  const intl = useIntl();

  const [audio] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dailyDigest, setDailyDigest] = useState<any>(null);
  useEffect(() => {
    if (audio) {
      (audio as HTMLAudioElement).play();
    }
  }, [audio]);

  const Info: FC<{
    title: React.ReactNode;
    value: React.ReactNode;
    bordered?: boolean;
  }> = ({ title, value, bordered }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{title}</span>
        <p>{value}</p>
        {bordered && <em />}
      </div>
    );
  };

  return (
    <>
      <Modal open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)} width={1000}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <span
              style={{
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              {intl.formatMessage({
                id: 'pages.welcome.daily_digest',
              })}
            </span>
          </div>
          <Card
            styles={{
              body: {
                border: '1px solid #f0f0f0',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.09)',
                padding: 10,
              },
            }}
          >
            <Row justify="space-around" align="middle">
              <Col sm={8} xs={24}>
                <Info
                  title={intl.formatMessage({
                    id: 'pages.welcome.sessions_today',
                  })}
                  value={dailyDigest?.sessions?.length}
                  bordered
                />
              </Col>
              <Col sm={8} xs={24}>
                <Info
                  title={intl.formatMessage({
                    id: 'pages.welcome.action_items_today',
                  })}
                  value={dailyDigest?.action_items?.length}
                  bordered
                />
              </Col>
              <Col sm={8} xs={24}>
                <Info
                  title={intl.formatMessage({
                    id: 'pages.welcome.action_items_today',
                  })}
                  value={dailyDigest?.goals?.length}
                />
              </Col>
            </Row>
          </Card>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              marginTop: 20,
            }}
          >
            {/* Action Items */}
            {dailyDigest?.action_items?.length !== 0 && (
              <div>
                <span style={global.tableTitle2}>
                  {intl.formatMessage({
                    id: 'pages.welcome.daily_digest_action_items',
                  })}
                </span>
                <List
                  size="small"
                  rowKey="id"
                  dataSource={dailyDigest?.action_items}
                  renderItem={(item: any) => (
                    <List.Item>
                      <div
                        onClick={() => {
                          history.push(`/action_items/${item.id}`, { data: item });
                        }}
                        style={{
                          ...global.tableRow,
                          width: '100%',
                          borderBottom: 'none',
                          padding: 0,
                        }}
                      >
                        <div style={{ ...global.tableTitleDescription, width: '100%' }}>
                          <Tooltip title={item.title} placement="topLeft">
                            <span style={{ ...global.tableTitle, width: '100%' }}>
                              {item.title}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </List.Item>
                  )}
                  locale={{
                    emptyText: (
                      <Empty
                        icon_source="/no_action_items.svg"
                        text_id="pages.daily_digest.no_action_items"
                      />
                    ),
                  }}
                />
              </div>
            )}

            {/* Sessions */}
            {dailyDigest?.sessions?.length !== 0 && (
              <div>
                <span style={global.tableTitle2}>
                  {intl.formatMessage({
                    id: 'pages.welcome.daily_digest_sessions',
                  })}
                </span>
                <List
                  size="small"
                  rowKey="id"
                  dataSource={dailyDigest?.sessions}
                  renderItem={(item: any) => (
                    <List.Item>
                      <div
                        onClick={() => {
                          history.push(`/sessions/${item.id}`, { data: item });
                        }}
                        style={{
                          ...global.tableRow,
                          width: '100%',
                          borderBottom: 'none',
                          padding: 0,
                        }}
                      >
                        <div style={{ ...global.tableTitleDescription, width: '100%' }}>
                          <Tooltip title={item.title} placement="topLeft">
                            <span style={{ ...global.tableTitle, width: '100%' }}>
                              {item.title}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </List.Item>
                  )}
                  locale={{
                    emptyText: (
                      <Empty
                        icon_source="/no_sessions.svg"
                        text_id="pages.daily_digest.no_sessions"
                      />
                    ),
                  }}
                />
              </div>
            )}

            {/* Goals */}
            {dailyDigest?.goals?.length !== 0 && (
              <div>
                <span style={global.tableTitle2}>
                  {intl.formatMessage({
                    id: 'pages.welcome.daily_digest_goals',
                  })}
                </span>
                <List
                  size="small"
                  rowKey="id"
                  dataSource={dailyDigest?.goals}
                  renderItem={(item: any) => (
                    <List.Item>
                      <div
                        onClick={() => {
                          history.push(`/goals/${item.id}`, { data: item });
                        }}
                        style={{
                          ...global.tableRow,
                          width: '100%',
                          borderBottom: 'none',
                          padding: 0,
                        }}
                      >
                        <div style={{ ...global.tableTitleDescription, width: '100%' }}>
                          <Tooltip title={item.name} placement="topLeft">
                            <span style={{ ...global.tableTitle, width: '100%' }}>{item.name}</span>
                          </Tooltip>
                        </div>
                      </div>
                    </List.Item>
                  )}
                  locale={{
                    emptyText: (
                      <Empty icon_source="/no_goals.svg" text_id="pages.daily_digest.no_goals" />
                    ),
                  }}
                />
              </div>
            )}

            {/* No Data */}
            {dailyDigest?.action_items?.length === 0 &&
              dailyDigest?.sessions?.length === 0 &&
              dailyDigest?.goals?.length === 0 && (
                <span style={{ ...global.tableTitle2, textAlign: 'center' }}>
                  {intl.formatMessage({
                    id: 'pages.welcome.daily_digest_no_data',
                  })}
                </span>
              )}
          </div>
        </div>
      </Modal>
      <Button
        id={'hover_effect'}
        style={{
          backgroundColor: colors.colorPalet5,
          fontWeight: '600',
          color: colors.paletContrast,
        }}
        onClick={() => {
          get('/api/users/daily_digest').then((res: any) => {
            setDailyDigest(res);
            setIsModalOpen(true);
          });
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 5,
          }}
        >
          <img
            alt="logo"
            src="/logo2.svg"
            style={{
              width: 20,
              height: 20,
            }}
          />
          {intl.formatMessage({
            id: 'pages.welcome.talk_star_maker',
          })}
        </div>
      </Button>
      <span
        style={{
          color: 'white',
          fontSize: '16px',
          rotate: '45deg',
          marginRight: 10,
          marginLeft: -10,
        }}
      >
        BETA
      </span>
    </>
  );
};

export const Question = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: 26,
      }}
      onClick={() => {
        window.open('https://pro.ant.design/docs/getting-started');
      }}
    >
      <QuestionCircleOutlined />
    </div>
  );
};
