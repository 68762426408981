// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from '/home/andre/Desktop/pramod/fredomaker/web/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import RocketOutlined from '/home/andre/Desktop/pramod/fredomaker/web/node_modules/@ant-design/icons/es/icons/RocketOutlined';
import CalendarOutlined from '/home/andre/Desktop/pramod/fredomaker/web/node_modules/@ant-design/icons/es/icons/CalendarOutlined';
import CheckCircleOutlined from '/home/andre/Desktop/pramod/fredomaker/web/node_modules/@ant-design/icons/es/icons/CheckCircleOutlined';
import FormOutlined from '/home/andre/Desktop/pramod/fredomaker/web/node_modules/@ant-design/icons/es/icons/FormOutlined';
import TeamOutlined from '/home/andre/Desktop/pramod/fredomaker/web/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import QuestionCircleOutlined from '/home/andre/Desktop/pramod/fredomaker/web/node_modules/@ant-design/icons/es/icons/QuestionCircleOutlined';
export default { HomeOutlined, RocketOutlined, CalendarOutlined, CheckCircleOutlined, FormOutlined, TeamOutlined, QuestionCircleOutlined };
