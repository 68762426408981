// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';
import api_config from '../config';

const API_URL = api_config.API_URL;

import Tools from '@/utils/Tools';

export async function currentUser(options?: { [key: string]: any }) {
  try {
    return JSON.parse(Tools.getData('current_user') || '{}');
  } catch (e) {
    console.log('Error parsing current_user', e);
    return undefined;
  }
}

export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>(API_URL + '/api/tokens', {
    method: 'DELETE',
    params: {
      access_token: Tools.getData('access_token'),
    },
    ...(options || {}),
  });
}

export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>(API_URL + '/api/tokens', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(body.email + ':' + body.password),
    },
    data: {
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
    },
  });
}

export async function register(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>(API_URL + '/api/users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
