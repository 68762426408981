import colors from '@/utils/styles/colors';
import { SoundOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import Loading from '../Loading';

function checkForLinks(text: string, setModalContent: any, setModalAudio: any) {
  // Check the text for <link></link> tags
  // but it can also be this <link href='/sessions/80'>here</link>

  const linkRegex = /<link(.*?)>(.*?)<\/link>/g;
  // const re = new RegExp('<link(.*?)>(.*?)</link>');

  // const links = text.match(linkRegex);
  // const links = text.matchAll(re);
  // console.log('links', links);
  const links = [...text.matchAll(linkRegex)];
  if (links) {
    let newText = [];
    let preIndex = 0;
    links.forEach((match) => {
      const link = match[0];
      // href='/action_items/229'
      // extract only whats after the href=' and before the last '
      let href_ = match[1]?.match(/href='(.*?)'/)?.[1];
      let modal_text = match[1]?.match(/modal_text='(.*?)'/)?.[1];
      let modal_audio = match[1]?.match(/modal_audio='(.*?)'/)?.[1];

      const linkText = match[2];
      const indexOfLink = text.indexOf(link);
      newText.push(<span>{text.slice(preIndex, indexOfLink)}</span>);
      if (href_) {
        newText.push(
          <a key={href_} href={href_}>
            {linkText}
          </a>,
        );
      } else if (modal_text) {
        newText.push(
          <a
            key={modal_text}
            onClick={() => {
              setModalContent(modal_text);
              if (modal_audio && modal_audio !== '') {
                setModalAudio(modal_audio);
              }
            }}
          >
            {linkText}
          </a>,
        );
      } else {
        newText.push(<a key={link}>{linkText}</a>);
      }
      preIndex = indexOfLink + link.length;
    });
    newText.push(<span>{text.slice(preIndex)}</span>);
    return newText;
  }
  return text;
}

export default function NewlineText({ text }: { text: string }) {
  const [modalContent, setModalContent] = useState(null);
  const [modalAudio, setModalAudio] = useState(null);

  const ModalContent = () => {
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    useEffect(() => {
      if (!audio && modalAudio) {
        setAudio(new Audio(modalAudio));
      }
      return () => {
        if (audio) {
          (audio as HTMLAudioElement).pause();
          setAudio(null);
        }
      };
    }, [audio]);

    const playAudio = () => {
      if (audio) {
        audio.play();
      } else {
        message.info('Audio is loading. Please wait a few seconds and try again.');
      }
    };

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
            gap: 10,
          }}
        >
          <span
            style={{
              fontSize: 20,
            }}
          >
            {'StarMaker Help'}
          </span>
          {modalAudio && (
            <SoundOutlined
              style={{
                fontSize: 20,
                color: colors.colorPalet5,
                cursor: 'pointer',
              }}
              onClick={() => {
                playAudio();
              }}
            />
          )}
        </div>
        <div>
          <Loading isLoading={modalContent === null} className="">
            <span
              style={{
                fontSize: 16,
              }}
            >
              {modalContent}
            </span>
          </Loading>
        </div>
      </div>
    );
  };

  if (!text || typeof text !== 'string') {
    return null;
  }

  return (
    <div>
      <Modal
        open={modalContent !== null}
        footer={null}
        onCancel={() => setModalContent(null)}
        destroyOnClose={true}
      >
        <ModalContent />
      </Modal>
      {text.split('\n').map((str) => {
        if (str.includes('\t')) {
          const tabs = str.split('\t');
          return tabs.map((tab) => {
            return (
              <p
                key={tab}
                style={{
                  marginLeft: '20px',
                }}
              >
                {tab}
              </p>
            );
          });
        }
        return <p key={str}>{checkForLinks(str, setModalContent, setModalAudio)}</p>;
      })}
    </div>
  );
}
