import { ProLayoutProps } from '@ant-design/pro-components';
import colors from '../src/utils/styles/colors';
/**
 * @name
 */
const Settings: ProLayoutProps & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  colorPrimary: colors.primary,
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'StarMaker',
  pwa: true,
  logo: 'logo.svg',
  iconfontUrl: '',
  token: {
    //https://procomponents.ant.design/components/layout#%E9%80%9A%E8%BF%87-token-%E4%BF%AE%E6%94%B9%E6%A0%B7%E5%BC%8F
    header: {
      colorBgHeader: colors.primary,
      colorHeaderTitle: 'white',
    },
  },
};

export default Settings;
