export default {
  sessions: 'Sessions',
  home: 'Home',
  goals: 'Goals',
  action_items: 'Action Items',
  goals_digest: 'Goals Assessment',
  sessions_digest: 'Sessions Overview',
  action_items_digest: 'Action Item summary',
  account: 'Account',
  title: 'Title',
  request_success: 'Request Success',
  request_error: 'Request Error',
  owner: 'Owner',
  name: 'Name',
  save: 'Save',
  edit: 'Edit',
  add: 'Add',
  yes: 'Yes',
  no: 'No',
  hello: 'Hello',
  generate_error: 'Error generating, please try again',
  generate_topics_error:
    'Unable to identify Topics for this session. Retry with revised title and/or detailed notes',
  generate_action_items_error:
    'Unable to identify Action Items. Retry with revised title and/or detailed notes',
  generate_loading: 'Generating with AI...',
  generate_loading_action_items: 'Starmaker identifying action items...',
  generate_loading_topics: 'Starmaker identifying topics...',
  cancel: 'Cancel',
  no_notes: 'Click here to start adding notes/context',
  generated_by_ai: 'Generated by AI',
  archived: 'Archived',
  deleted: 'Deleted',
  delete: 'Delete',
  pop_confirm_delete_title: 'Are you sure to delete this?',
  pop_confirm_delete_description: 'This action cannot be undone',
  no_transcript: 'Click here to start adding transcript',
  update: 'Update',
  email: 'Email',
  files: 'Files',
  upload_file: 'Upload File',
  responsible: 'Responsible',
  clean_up: 'Clean Up',
  week: 'Week',
  month: 'Month',
  coming_soon: 'Coming Soon',
  send: 'Send',
  refer_a_friend: 'Refer a Friend',
  enter_friend_email: 'Enter your friend email',
  for_example: 'For example:',
  upload_training_materials: 'Upload management training material',
  upload_training_materials_text:
    'StarMaker will soon be able to leverage your company’s management training material to provide customized coaching in a variety of situations.',

  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',

  one_on_one: 'One on One',
  start: 'Start',
  client_id: 'Client ID',
  client_secret: 'Client Secret',
  add_participants: 'Add Participants',
  participants: 'Participants',
  add_goal: 'Add Goal',
  clear_chat_history: 'Clear Chat History',
  back_bottom: 'Back to bottom',
  chat_hello_message: 'Hello, how can I help you today?',
  chat_placeholder: 'Type a message...',

  'pages.welcome.coach': 'Coach',
  'pages.layouts.userLayout.title': ' ',
  'pages.login.sso': 'Single Sign-On',
  'pages.login.accountLogin.tab': 'Already have an account?',
  'pages.login.name.placeholder': 'Name',
  'pages.login.email.placeholder': 'Email',
  'pages.login.accountRegister.tab': 'Don’t have an account?',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password(admin/ant.design)',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.registerSuccess': 'Register successful!',
  'pages.login.registerFail': 'Register failed, please try again!',
  'pages.login.username.placeholder': 'Username/Email',
  'pages.login.username.required': 'Please input your username or email!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.login.forgot_password': 'Forgot password?',
  'pages.login.forgot_password_no_email': 'You need to input your email to reset your password',
  'pages.login.set_password': 'Set Password',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.login': 'Already have an account?',
  'pages.login.registerAccount': 'Don’t have an account?',
  'pages.login.register': 'Register',
  'pages.login.sso_domain': 'Domain',
  'pages.login.sso_domain_placeholder': 'Please enter your company domain. Example: starmaker.com',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.404.subTitle': 'Sorry, the page you visited does not exist.',
  'pages.404.buttonText': 'Back Home',

  'pages.greetings.industry': 'Industry',
  'pages.greetings.industry.placeholder': 'What industry are you in?',
  'pages.greetings.role': 'Role',
  'pages.greetings.role.placeholder': 'What is your role?',
  'pages.greetings.principles_values': 'Principles & Values',
  'pages.greetings.principles_values.placeholder':
    'Management principles and values: some choices like grit, ethics, curiosity, or other custom values.',

  'pages.home.logout': 'Logout',
  'pages.home.center': 'Center',
  'pages.home.settings': 'Settings',

  'pages.welcome.chat': 'Talk To StarMaker',
  'pages.welcome.due_date': 'Due Date: ',
  'pages.welcome.goals': 'Open Goals',
  'pages.welcome.no_goals': 'No Goals',
  'pages.welcome.action_items': 'Action Items',
  'pages.welcome.sessions': 'Sessions',
  'pages.welcome.upcoming_sessions': 'Upcoming Sessions',
  'pages.welcome.all_goals': 'All Goals',
  'pages.welcome.all_action_items': 'All Action Items',
  'pages.welcome.all_sessions': 'All Sessions',
  'pages.welcome.talk_star_maker': 'Talk To StarMaker',
  'pages.welcome.sessions.all': 'All',
  'pages.welcome.sessions.upcoming': 'Upcoming',
  'pages.welcome.sessions.past': 'Past',
  'pages.welcome.todo': 'To-do',
  'pages.welcome.in_progress': 'In Progress',
  'pages.welcome.completed': 'Completed',
  'pages.welcome.goals_to_date': 'Goals Achieved',
  'pages.welcome.open_goals': 'Open Goals',
  'pages.welcome.open_action_items': 'Open Action Items',
  'pages.welcome.no_action_items': 'No Action Items, click here to add a new action item',
  'pages.welcome.no_sessions': 'No Sessions, click here to add a new session',
  'pages.welcome.greetings': 'Greetings',
  'pages.welcome.daily_digest': 'Daily Digest',
  'pages.welcome.sessions_today': 'Sessions Today',
  'pages.welcome.action_items_today': 'Action Items Today',
  'pages.welcome.goals_today': 'Goals Today',
  'pages.welcome.daily_digest_action_items': 'These action items need your attention:',
  'pages.welcome.daily_digest_sessions': 'You have these sessions today:',
  'pages.welcome.daily_digest_goals': 'You have these goals today:',
  'pages.welcome.focusing_goals': 'Goals for the week',
  'pages.welcome.no_focus_goals': 'You need to plan your next week or click here to add a new goal',
  'pages.welcome.chat_suggestions': 'Chat Suggestions',

  'pages.sessions.total_sessions': 'Total Sessions to Date',
  'pages.sessions.upcoming': 'Upcoming',
  'pages.sessions.past': 'Past',
  'pages.sessions.upcoming_sessions': 'Upcoming Sessions',
  'pages.sessions.past_sessions': 'Past Sessions',
  'pages.sessions.sessions': 'Sessions',
  'pages.sessions.participants': 'Participants',
  'pages.sessions.add_session': 'Add a new Session',
  'pages.sessions.action_items': 'Action Items',
  'pages.sessions.sentiment': 'Sentiment',
  'pages.sessions.new_session': 'New Session',
  'pages.sessions.title_placeholder': 'New session Title',
  'pages.sessions.date': 'Date',
  'pages.sessions.topics': 'Topics',
  'pages.sessions.add_topic': 'Add Topic',
  'pages.sessions.topic_title': 'Topic Title',
  'pages.sessions.no_sessions': 'No Sessions, click here to add a new session',
  'pages.session.private_notes': 'Private Notes',

  'maintenance.title': 'Under maintenance',
  'maintenance.subtitle': 'Sorry, the server is under maintenance. Please try again later.',

  'menu.action_item': 'Action Item',
  'menu.goal': 'Goal',
  'menu.session': 'Session',
  'pages.session.originating_action_items': 'Originating Action Items',
  'pages.session.participants': 'Participants',
  'pages.session.topics': 'Topics',
  'pages.session.context_notes': 'Notes',
  'pages.session.related_sessions': 'Related Sessions',
  'pages.session.add_topic': 'Add Topic',
  'pages.session.generate_topics': 'Suggest an agenda',
  'pages.session.generate_topics_retry': 'Retry Suggest an agenda',
  'pages.session.generate_action_items': 'Suggest Action Items',
  'pages.session.generate_action_items_retry': 'Retry Suggest Action Items',
  'pages.session.prepare': 'Prepare',
  'pages.session.prepare_message':
    'I will soon be able to help you prepare for various types of meetings based on notes and meeting history.',

  'pages.session.no_notes': 'No notes',
  'pages.session.edit': 'Edit',
  'pages.session.expand': 'Expand',
  'pages.session.associate_action_item': 'Associate Action Item',
  'pages.session.associated_action_item': 'Action Item',
  'pages.session.transcript': 'Transcript',
  'pages.session.created_action_items': 'Created Action Items',
  'pages.session.no_action_items': 'No Action Items',
  'pages.session.no_topics': 'No Topics',
  'pages.session.no_sessions': 'No Sessions',
  'pages.session.duration': 'Session Duration',
  'pages.session.decisions_made': 'Decisions Made',
  'pages.session.no_decisions':
    'Need to fill the transcript to get the decisions made in the session',
  'pages.session.get_prepared': 'Get Prepared for the upcoming session',
  'pages.session.no_files': 'No Files',
  'pages.session.generate_notes': 'Generate Notes',

  'pages.ActionItems.open': 'Open',
  'pages.ActionItems.completed': 'Completed',
  'pages.ActionItems.scheduled': 'Scheduled',
  'pages.ActionItems.completed/Total': 'Progress Completed/Total',
  'pages.ActionItems.ActionItems': 'Action Items',
  'pages.ActionItems.open_card': 'Open Action Items',
  'pages.ActionItems.completed_card': 'Completed Action Items',
  'pages.ActionItems.add_ActionItems': 'Add a new Action Item',
  'pages.ActionItems.due_date': 'Due Date',
  'pages.ActionItems.linked_sessions': 'Linked Sessions',
  'pages.ActionItems.archived': 'Archived',
  'pages.action_item.completed': 'Completed',
  'pages.action_item.due_date': 'Due Date',
  'pages.ActionItems.title_placeholder': 'New Action Item Title',
  'pages.ActionItems.owner_placeholder': 'New Action Item Owner',
  'pages.ActionItems.new_action_item': 'New Action Item',
  'pages.ActionItems.no_action_items': 'No Action Items',
  'pages.ActionItems.no_action_items_add': 'No Action Items, click here to add a new action item',
  'pages.action_items.goals': 'Goals',

  'pages.action_item.actions': 'Actions',
  'pages.action_item.archive': 'Archive Action Item',
  'pages.action_item.archive_task': 'Archive Task',
  'pages.action_item.un_archive': 'Un-Archive Action Item',
  'pages.action_item.un_archive_task': 'Un-Archive Task',
  'pages.action_item.remove_completion': 'Remove Completion',
  'pages.action_item.complete': 'Complete Action Item',
  'pages.action_item.complete_task': 'Complete Task',
  'pages.action_item.delete': 'Delete Action Item',
  'pages.action_item.change_due_date': 'Change Action Item Due Date',
  'pages.action_item.participants': 'Participants',
  'pages.action_item.context_notes': 'Notes',
  'pages.action_item.no_notes': 'No notes',
  'pages.action_item.expand': 'Expand',
  'pages.action_item.edit': 'Edit',
  'pages.action_item.linked_sessions': 'Linked Sessions',
  'pages.action_items.upcoming': 'Upcoming',
  'pages.action_items.past': 'Past',
  'pages.action_item.no_action_items': 'No Action Items',
  'pages.action_item.associated_goal': 'Goals',
  'pages.action_item.schedule_session': 'Schedule Session',
  'pages.action_item.title': 'Title',
  'pages.action_item.date': 'Date',
  'pages.action_item.session_created_action_item': 'This Session created the current Action Item',
  'pages.action_item.action_item_created_session': 'The current Action Item created this session',
  'pages.action_item.add_goals': 'Add Goals',
  'pages.action_item.add_participant': 'Add participant',

  'pages.goal.associated_session': 'Associated Session',
  'pages.goal.associated_session_tooltip': 'This goal was created from this session',

  'pages.goals': 'Goals',
  'pages.goals.open_card': 'Open Goals',
  'pages.goals.completed_card': 'Completed Goals',
  'pages.goals.completed/Total': 'Progress Completed/Total',
  'pages.goals.open': 'Open',
  'pages.goals.completed': 'Completed',
  'pages.goals.add_goal': 'Add a new Goal',
  'pages.goals.new_goal': 'New Goal',
  'pages.goals.action_items_progression': 'Action Items Progress',
  'pages.goals.sessions': 'Sessions',
  'pages.goals.name_placeholder': 'New Goal Name',
  'pages.goals.action_items': 'Action Items',
  'pages.goals.action_items_placeholder': 'Select the Action Items for the new goal',
  'pages.goals.no_goals': 'No Goals, click here to add a new goal',
  'pages.goals.digest': 'Goals Digest',
  'pages.goals.tag_filter': 'Tag Filter',
  'pages.goals.archive': 'Archive Goal',
  'pages.goals.archived': 'Archived',
  'pages.goals.un_archive': 'Un-Archive Goal',

  'pages.goal.upcoming_sessions_count': 'Upcoming Sessions Count',
  'pages.goal.open_action_items': 'Open Action Items',
  'pages.goal.completed/Total': 'Progress Completed/Total',
  'pages.goal.action_items': 'Action Items',
  'pages.goal.action_item_open': 'Open Action Items',
  'pages.goal.action_item_completed': 'Completed Action Items',
  'pages.goal.sessions': 'Sessions',
  'pages.goal.upcoming': 'Upcoming',
  'pages.goal.past': 'Past',
  'pages.goal.context_notes': 'Notes',
  'pages.goal.no_context': 'No context',
  'pages.goal.expand': 'Expand',
  'pages.goal.edit': 'Edit',
  'pages.goal.no_action_items': 'No Action Items, you can use AI to generate or add manually',
  'pages.goal.no_sessions': 'No Sessions',
  'pages.goal.date': 'Date',
  'pages.goal.tags': 'Tags',
  'pages.goal.add_tag': 'Add Tag',

  'pages.daily_digest.no_action_items': 'No Action Items for today',
  'pages.daily_digest.no_sessions': 'No Sessions for today',
  'pages.daily_digest.no_goals': 'No Goals for today',
  'pages.welcome.daily_digest_no_data':
    "It looks like everything is done for today! You're becoming a star already",

  // 'starmaker.no_goals': 'You do not have any goals set.',
  'starmaker.no_goals': "You have no goals set. Time to set new goals? Let's go.",
  'starmaker.goals':
    'You have {x} goal{x1} set. Make sure you have action items to help achieve the goal!',
  'starmaker.less_50_goals': 'You need to make more progress in your goals.',
  'starmaker.more_50_goals': 'You are making good progress in your goals.',

  'pages.sessions.digest': 'Sessions Digest',
  // 'starmaker.no_sessions': 'You do not have any sessions scheduled.',
  'starmaker.no_sessions':
    'Hmm… looks like you have no upcoming sessions. Time for some deep work and planning?',
  'starmaker.sessions':
    'You have {x} upcoming session{x1} for today. Review to make sure they all have a clear agenda and you are prepared.',

  'starmaker.sessions_need_preparation': 'You have sessions that need preparation.',
  'starmaker.sessions_prepared': 'All upcoming sessions are prepared.',
  // 'starmaker.no_action_items': 'You do not have any action items.',
  'starmaker.no_action_items':
    'You have no open action items. Review your goals and make sure they have follow up action items to help you make progress.',
  'starmaker.action_items':
    'You have {x} open action item{x1}. Review due dates and schedule time to take action.',
  'pages.action_items.digest': 'Action Items Digest',
  'starmaker.action_items_past_due_date': 'You have action items past their due date.',
  'starmaker.action_items_good': 'You are on track with your action items.',

  'pages.greetings.title': 'Greetings',
  'pages.greetings.submit': 'Submit',
  'pages.greetings.company_name': 'Company Name',
  'pages.greetings.company_name.placeholder': 'Company Name',

  'starmaker.home.no_data':
    'Looks like you have a clean plate. Time to set new goals? What do you want to do next?',
  'starmaker.home.data':
    'You have {x} open goal{x1}, {y} action item{y1} open today,  and {z} session{z1} for today.',

  'pages.profile': 'Profile',

  'pages.WeeklyPlanner.title': 'Week Ahead',
  'pages.WeeklyPlanner.step1.title': 'Goals',
  'pages.WeeklyPlanner.step1.description': 'Pick the goals you want to focus on this week',
  'pages.WeeklyPlanner.step1.no_goals_selected_error': 'You need to select at least one goal',

  'pages.WeeklyPlanner.step2.title': 'Prioritize Action Items',
  'pages.WeeklyPlanner.step2.description':
    'Move the action items to the up/down to prioritize them',

  'pages.WeeklyPlanner.step3.title': 'Clean Up Action Items',
  'pages.WeeklyPlanner.step3.description': 'Make a decision for the remaining action items',
  'pages.WeeklyPlanner.success': 'Weekly assessment completed successfully',

  'pages.CleanUp.title': 'Clean Up Action Items',
  'pages.CleanUp.filter': 'Clean up inactive action items for more then: ',
  'pages.CleanUp.cardTitle': 'These action items need your attention',

  'pages.coach.comming_soon_card_1': 'Prepare for 1:1s with your team members or your manager',
  'pages.coach.comming_soon_card_2':
    'Make a business case to purchase a software application to improve productivity',
  'pages.coach.comming_soon_card_3':
    'Communicate change of direction and revised strategic priorities to your team',
  'pages.coach.title':
    'As I learn more about your style and circumstances I can help you be prepared to deal with a variety of challenging situations as a leader.',

  'pages.coach.scaling_tec_team': 'Scaling your technical team',
  'pages.coach.recruiting_engine': 'Building your recruiting engine',
  'pages.coach.performance': 'Performance management and leveling',
  'pages.coach.onboarding': 'Onboarding Process',
  'pages.coach.dealing_complexity': 'Dealing with complexity',
  'pages.coach': 'Coach',

  'integrations.connect': 'Connect to external issue tracking systems',
  'jira.connect': 'Connect to Jira',
  'jira.connect_message':
    'I will soon be able to connect to Jira and other popular issue tracking systems and suggest high level goals.',
  'jira.auth.title': 'Authenticate with Jira',
  'jira.auth.description': 'Connect to your Jira account to get started',
  'jira.project.title': 'Select a project',
  'jira.project.description': 'Select a project from where to get the issues',
  'jira.goals.title': 'Select Goals',
  'jira.goals.description': 'Accept the goals you that you think are correct',
  'jira.url': 'Jira URL',
  'jira.url_required': 'Please input your Jira URL',
  'jira.url_placeholder': 'https://yourcompany.atlassian.net',
  'jira.email': 'Email',
  'jira.email_required': 'Please input your email',
  'jira.email_placeholder': 'Email',
  'jira.api_token': 'API Token',
  'jira.api_token_required': 'Please input your API Token',
  'jira.api_token_placeholder': 'API Token',
  'jira.project': 'Project',
  'jira.project_required': 'Please select a project',
  'jira.project_placeholder': 'Select a project',
  'clickup.connect': 'Connect to ClickUp',
  'clickup.goals': 'Select Goals',
  'clickup.connect_tooltip':
    'To connect to clickup you need to create a Clickup APP, for that you need to go to settings -> ClickUp API -> Create an APP and fill app name with StarMaker and the redirect URL with https://starmaker.makeshop.ai',
  'pages.tasks.title': 'Tasks',
  'pages.action_item.no_tasks': 'No tasks',
};
