import React from 'react';
import { Spin } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons'

export default function Loading({
  isLoading,
  children,
  className,
}: {
  isLoading: boolean;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <Spin spinning={isLoading} wrapperClassName={className}>
      {children}
    </Spin>
  );
}
