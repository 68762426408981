import global from '@/utils/styles/global';
import { useIntl } from '@umijs/max';
import React from 'react';

interface EmptyProps {
  icon_source: string;
  text_id: string;
  text_style?: object;
  AddForm?: (callback: any) => React.ReactElement;
}

const Empty: React.FC<EmptyProps> = ({ icon_source, text_id, text_style, AddForm }) => {
  const intl = useIntl();
  const [showAddForm, setShowAddForm] = React.useState(false);

  return (
    <div>
      {showAddForm && AddForm ? (
        <AddForm
          callback={() => {
            setShowAddForm(false);
          }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
            cursor: AddForm ? 'pointer' : 'auto',
          }}
          onClick={() => setShowAddForm(true)}
        >
          <img
            src={icon_source}
            alt="empty"
            style={{
              width: '100px',
            }}
          />
          <span style={text_style || { ...global.tableDescription, color: 'black' }}>
            {intl.formatMessage({
              id: text_id,
            })}
          </span>
        </div>
      )}
    </div>
  );
};

export default Empty;
