class Tools {
  setData(key: string, data: string) {
    localStorage.setItem(key, data);
  }
  getData(key: string) {
    return localStorage.getItem(key);
  }

  removeData(key: string) {
    localStorage.removeItem(key);
  }

  parsePagination(pagination: any) {
    return {
      current: pagination?.page,
      pageSize: pagination?.per_page,
      total: pagination?.count,
    };
  }
  uniqueId() {
    // always start with a letter (for DOM friendlyness)
    let idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));

    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      const ascicodeChar = Math.floor(Math.random() * 25 + 65);
      idstr += String.fromCharCode(ascicodeChar);
      idstr += Math.floor(Math.random() * 99);
    } while (idstr.length < 8);

    return idstr;
  }
  isValidHttpUrl(string: any) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  optionsToUrl(options: any, route: string) {
    let query = '';
    if (Object.keys(options).length > 0) {
      query = route.includes('?') ? '&' : '?';
      for (const [key, value] of Object.entries(options)) {
        if (
          value === null ||
          value === undefined ||
          value === '' ||
          (Array.isArray(value) && value.length === 0)
        ) {
          continue;
        }
        if (Array.isArray(value)) {
          query += `${key}=`;
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          value.forEach((item, index) => {
            query += `${index === 0 ? '' : ','}${item}`;
          });
          query += '&';
        } else {
          query += key + '=' + value + '&';
        }
      }
      query = query.slice(0, -1);
    }
    return query;
  }

  generateColorByName(name: string) {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 8) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).slice(-2);
    }
    return color;
  }
  validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }

  verifyPermission(route: string, method: string) {
    const permited_routes = JSON.parse(this.getData('current_user') || '{}')?.permited_routes;
    if (!permited_routes || permited_routes?.length === 0) {
      return true;
    }
    const permited_route = permited_routes.find(
      (item: any) => item.route === route && item.methods.includes(method),
    );
    if (permited_route) {
      return true;
    }
    return false;
  }

  verifyNavigationPermission(navigation?: string) {
    const permited_navigations = JSON.parse(
      this.getData('current_user') || '{}',
    )?.permited_navigation;
    if (!permited_navigations || permited_navigations?.length === 0) {
      return true;
    }
    return permited_navigations.includes(navigation);
  }
}

export default new Tools();
